@font-face {
  font-family: "SevenSegment";
  src: url("SevenSegment.ttf") format("truetype");
}  

body, html {
  background: #2A1A1A;
  overflow: hidden;
  font-size: 10vw;
  transition: background .1s;
}
body:active {
  background: #443333;
}

.kaffe {
  text-align: center;
  width: 100vw;
  height: 100vh;
  position: relative;

  color: #EEAA66;
}

.vertcenter {
  position: absolute;
  top: 50%;
  left: 50%;
  -ms-transform: translateX(-50%) translateY(-50%);
  -webkit-transform: translate(-50%,-50%);
  transform: translate(-50%,-50%);
}
.display {
  width: 100vw;
  -ms-transform: skew(-5deg);
  -webkit-transform: skew(-5deg);
  transform: skew(-5deg);
}
.minidisplay {
  height: 6vw;
  margin-bottom: 3vw;
}
.minidisplay .sevenSegment,
.minidisplay .colon,
.minidisplay .period,
.minidisplay svg {
  vertical-align: top;
}
.minidisplay .sevenSegment {
  width: 4vw;
  height: 6vw;
}
.minidisplay .colon, .minidisplay .period {
  width: 1vw;
  height: 6vw;
}

.sevenSegment {
  display: inline-block;
  position: relative;
  width: 16vw;
  height: 24vw;
  vertical-align: bottom;
}
.colon {
  display: inline-block;
  vertical-align: bottom;
  width: 4vw;
  height: 24vw;
}
.period {
  display: inline-block;
  vertical-align: bottom;
  width: 4vw;
  height: 24vw;
}

.explanation {
  font-family: "SevenSegment";
  -ms-transform: skew(-5deg);
  -webkit-transform: skew(-5deg);
  transform: skew(-5deg);

  margin-top: 5vh;
  font-size: 0.75rem;
}

.sevenSegment svg, .colon svg {
  width: 100%;
  height: 100%;
}